'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';

import { Button, Container, Heading, Icon, Input } from '@/components';

export default function NotFound() {
  const [input, setInput] = useState('');
  const router = useRouter();

  const handleChangeInput = (value: string) => {
    setInput(value);
  };

  const handleReturnToHome = () => {
    router.push('/');
  };

  return (
    <main>
      <Container className="page_not_found">
        <section className="page_not_found__content">
          <Heading className="heading" level={1}>
            404
          </Heading>
          <p className="heading__descrip">Oops! Page not found.</p>
          <p className="page_not_found__content__description">
            We couldn’t find the page you are looking for.
            <br />
            Perhaps you can try searching:
          </p>
        </section>
        <section className="page_not_found__form">
          <Input
            className="input_search"
            icon={<Icon name="search" />}
            onChange={e => handleChangeInput(e.target.value)}
            placeholder="Search"
            type="search"
            value={input}
          />
          <Button color="primary" onClick={handleReturnToHome}>
            Return to Home
          </Button>
        </section>
      </Container>
    </main>
  );
}
